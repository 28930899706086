import React from "react";
import {connect} from "react-redux"
import {bindActionCreators} from "redux";
import {actionCreators} from "../reducer/AuthReducer";
import "./LoginPage.css"
import { withRouter } from "react-router";
import {openInNewTab} from "../util/WebUtils";
import {config} from "../util/EnvConfig";

class WelcomeUserSetup  extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            btn_disabled: false,
            checkboxChecked: false,
        };
        this.usernameRef = React.createRef();
        this.inviteCodeRef = React.createRef();
        this.companyRef = React.createRef();
        this.personNameRef = React.createRef();
        this.passwordRef = React.createRef();
        this.passwordRefSecond = React.createRef();
        this.verify_usernameRef = React.createRef();
        this.verify_codeRef = React.createRef();
        this.reset_usernameRef = React.createRef();
        this.pwdStrength = 0;
    }

    componentDidMount() {

    }

    componentDidUpdate(prevProps) {
    }

    handleCheckboxChange(evt) {
        this.setState({ checkboxChecked: evt.target.checked });
    }

    render() {
        let message = "One verify email is sent to your registered email address. Please check your email and follow the instruction to complete registration.";
        return (
            <div className="login-form">
                <div className="form-group">
                    {message}
                </div>
            </div>
        );
    }
}

export default connect(
    state => state.auth,
    dispatch => bindActionCreators(actionCreators, dispatch)
)(withRouter(WelcomeUserSetup));
