import React from "react";
import {connect} from "react-redux"
import {bindActionCreators} from "redux";
import {actionCreators} from "../reducer/AuthReducer";
import "./LoginPage.css"
import { withRouter } from "react-router";
import {openInNewTab} from "../util/WebUtils";
import {config} from "../util/EnvConfig";

class VerifyPage extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
          loading: false,
          btn_disabled: false,
          checkboxChecked: false,
        };
        this.usernameRef = React.createRef();
        this.inviteCodeRef = React.createRef();
        this.companyRef = React.createRef();
        this.personNameRef = React.createRef();
        this.passwordRef = React.createRef();
        this.passwordRefSecond = React.createRef();
        this.verify_usernameRef = React.createRef();
        this.verify_codeRef = React.createRef();
        this.reset_usernameRef = React.createRef();
        this.pwdStrength = 0;
    }

    componentDidMount() {
        let query =  new URLSearchParams(this.props.location.search);
        //add query check.
        let userEmail = query.get("userEmail");
        let verifyCode = query.get("verifyCode");
        this.verify_click(userEmail, verifyCode)
    }

    componentDidUpdate(prevProps) {
      this.reset_spinner();
    }

    handleCheckboxChange(evt) {
      this.setState({ checkboxChecked: evt.target.checked });
    }


    reset_spinner() {
      if (this.state.btn_disabled) {
        this.setState({btn_disabled: false, loading: false});
      }
    }

    verify_click(email, verify_code) {
      this.props.clearError();
      this.setState({btn_disabled: true, loading: true});
      this.props.verifyEmail(email, verify_code);
    }

    openTidy3DClient() {
        return openInNewTab(config.tidy3d.webclient,"Tidy3dClient");
    }

    render() {
        const {verifyError} = this.props;
        let message = "Verifying the user email, please wait...";
        if(verifyError) {
            message =verifyError.err_msg;
        }
          return (
               <div className="login-form">
                   <div className="form-group">
                       {message}
                   </div>
                   <button
                       className="btn btn-primary btn-block"
                       onClick={() => this.openTidy3DClient()}>Go Tidy3D Client
                   </button>
               </div>
          );
    }
}

export default connect(
    state => state.auth,
    dispatch => bindActionCreators(actionCreators, dispatch)
)(withRouter(VerifyPage));
