import React from 'react';
import './App.css';
import LoginPage from "./page/LoginPage";
import {BrowserRouter as Router, Route, Switch} from "react-router-dom";
import SignupPage from "./page/SignupPage";
import history from "./history"
import VerifyUser from "./page/VerifyPage";
import ResetPasswordVerifyPage from "./page/ResetPasswordVerifyPage";
import ResetPasswordPage from "./page/ResetPasswordPage";
import WelcomeUserSetup from "./page/WelcomeUserSetup";

class App extends React.Component {

    render() {
        return (
            <Router history={history}>
                <div>
                    <Switch>
                        <Route path="/app/login" render={() => (<LoginPage location={this.props.location}/>)}/>
                        <Route path="/app/signup" render={() => (<SignupPage resetPassword={null} verifyInfo={null}/>)}/>
                        <Route path="/app/verify" render={() => (<VerifyUser />)}/>
                        <Route path="/app/reset" render={() => (<ResetPasswordPage />)}/>
                        <Route path="/app/resetVerify" render={() => (<ResetPasswordVerifyPage />)}/>
                        <Route path="/app/newUserSetup" render={() => (<WelcomeUserSetup />)}/>
                    </Switch>
                    {process.env.REACT_APP_RUNTIME_ENV != 'PROD' &&
                    <div><label>Ver:{process.env.REACT_APP_RUNTIME_ENV}-{process.env.REACT_APP_GIT_VER}</label></div>
                    }
                    <div><br/></div>
                </div>
            </Router>

        );
    }
}

export default App;
