import {sha512} from "js-sha512"
import history from "../history"
import {
  AUTH_HEADER, signup, verify_email,
  test_password_strength, xzdfg, reset_password, reset_pwd_with_code,
  is_str_empty_or_blank, callGetWithBasicAuth2, callPost2WithToken, toUsername
} from "./utils";
import createAction from "./utils";

const loginType = 'LOGIN';
const clearError = 'CLEAR_ERR';
const loginError = 'LOGIN_ERR';
const signupError = 'SIGNUP_ERR';
const verifyError = 'VERIFY_ERR';
const resetNotice = 'resetNotice';
const salt = "5ac0e45f46654d70bda109477f10c299";
const loginValidDays = 7;
export const LOGIN_CONTROL_KEY = "login_info";

const initialState = {accessToken: null};

export const actionCreators = {
    signup: (username, password, passwordSecond, zxc, personName, company, callback) =>(dispatch, state) => {

        zxc = zxc.toUpperCase().trim();
        if (is_str_empty_or_blank(zxc) || !xzdfg(zxc)) {
          return dispatch(createAction(signupError, {
              err_msg: 'W'+'rong '+'In'+'vite'+' Co'+'de',
          }));
        }
        if (is_str_empty_or_blank(username)) {
          return dispatch(createAction(signupError, {
              err_msg: 'Please fill in email.',
          }));
        }
       
        if (test_password_strength(password) < 4) {
          return dispatch(createAction(signupError, {
              err_msg: 'Please choose stronger password to protect your account',
          }));
        }
        if (password === passwordSecond) {

          signup(username, password, zxc, personName, company, (err_obj) => {
              if (err_obj) {
                return dispatch(createAction(signupError, {
                    err_msg: err_obj.message,
                }));
              } else {
                  callback();
              }
          });
        } else {
          return dispatch(createAction(signupError, {
              err_msg: 'Please confirm your passwords match',
          }));
        }
    },
    verifyEmail:  (username, verify_code) =>(dispatch, state) => {
        if (username && verify_code) {
          verify_email(username, verify_code, (err_obj) => {
              if (err_obj) {
                return dispatch(createAction(verifyError, {
                    err_msg: err_obj.message,
                    isVerified: false
                }));
              } else {
                return dispatch(createAction(verifyError, {
                    isVerified: true,
                    err_msg: 'Your email is verified, please click below to use Tidy3d.',
                }));
              }
          });
        } else {
          return dispatch(createAction(verifyError, {
              err_msg: 'Please type in both email and verification code.',
          }));
        }
    },
    requestReset:  (username) =>(dispatch, state) => {
        dispatch(createAction(resetNotice, null));
        if (username) {
          reset_password(username,
            (err_obj) => {
                return dispatch(createAction(resetNotice, {
                    notice: err_obj.message,
                }));
            },
            (result) => {
                return dispatch(createAction(resetNotice, {
                    notice: 'Please check your email to get verification code and reset your password.',
                }));
            });
        } else {
          return dispatch(createAction(resetNotice, {
              notice: 'Please type in your email',
          }));
        }
    },
    resetPwd: (username, verify_code, pwdOne, pwdTwo) =>(dispatch, state) => {
        dispatch(createAction(resetNotice, null));
        if (is_str_empty_or_blank(username) || is_str_empty_or_blank(verify_code)) {
          return dispatch(createAction(resetNotice, {
              notice: 'Please fill in email and verification code in order to reset password!',
          }));
        }
        if (test_password_strength(pwdOne) < 4) {
          return dispatch(createAction(resetNotice, {
              notice: 'Please choose stronger password to protect your account',
          }));
        }
        if (pwdOne === pwdTwo) {
          reset_pwd_with_code(username, pwdOne, verify_code,
            () => {
                return dispatch(createAction(resetNotice, {
                    notice: 'Password is reset successfully! Click Log In to sign in.',
                    isSuccess: true
                }));
            },
            (err_obj) => {
                return dispatch(createAction(resetNotice, {
                    notice: err_obj.message,
                    isSuccess: false
                }));
            });
        } else {
          return dispatch(createAction(resetNotice, {
              notice: 'Please confirm your passwords match',
              isSuccess: false
          }));
        }
    },
    clearError: () => (dispatch, state) => {
        return dispatch(createAction(clearError, {}));
    },
    login: (username, password) =>(dispatch, state) => {
        const encrypedPwd = sha512(password + salt);
        return callGetWithBasicAuth2("auth", username, encrypedPwd)
            .then(resp => {
                return dispatch(createAction(loginType, {
                    secretAccessKey: resp.data.data.user.userSecretAccessKey,
                    accessKeyId: resp.data.data.user.userAccessKey,
                    admin: resp.data.data.user.role == 'admin',
                    ...resp.data.data.user,
                    ...resp.data.data.auth
                }));
            })
            .catch(err => {
                return dispatch(createAction(loginError, {
                    err_msg: err.response.hasOwnProperty("data") ? err.response.data.error : "unknown error!"
                }));
            });

    },
};

export function reducer(state = initialState, action) {
    state = state || initialState;
    console.log('auth.reducer', action);
    if (action.type === loginType) {
        localStorage.setItem(AUTH_HEADER, JSON.stringify(action.payload));
        let cur_date = new Date();
        cur_date.setDate(cur_date.getDate() + loginValidDays);
        let control_info = {login_time: new Date().getTime(), expire_time: cur_date.getTime()};
        localStorage.setItem(LOGIN_CONTROL_KEY, JSON.stringify(control_info));
    } else if (action.type === loginError) {
      return {...state, loginError: action.payload};
    } else if (action.type === signupError) {
      return {...state, signupError: action.payload};
    } else if (action.type === verifyError) {
      return {...state, verifyError: action.payload, signupError: null};
    } else if (action.type === clearError) {
      return {...state, loginError: null, signupError: null, verifyError: null, resetNotice: null};
    } else if (action.type == resetNotice) {
      return {...state, loginError: null, signupError: null, verifyError: null, resetNotice: action.payload};
    }

    return state;
};
