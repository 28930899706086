import React from "react";
import {connect} from "react-redux"
import {bindActionCreators} from "redux";
import {actionCreators} from "../reducer/AuthReducer";
import "./LoginPage.css"
import { withRouter } from "react-router";
import {Alert, OverlayTrigger, Popover, ProgressBar} from "react-bootstrap";
import {BarLoader} from "react-spinners";
import {test_password_strength} from "../reducer/utils";
import {openInNewTab} from "../util/WebUtils";
import {config} from "../util/EnvConfig";

class ResetPasswordVerifyPage extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
          loading: false,
          btn_disabled: false,
          checkboxChecked: false,
        };
        this.usernameRef = React.createRef();
        this.inviteCodeRef = React.createRef();
        this.companyRef = React.createRef();
        this.personNameRef = React.createRef();
        this.passwordRef = React.createRef();
        this.passwordRefSecond = React.createRef();
        this.verify_usernameRef = React.createRef();
        this.verify_codeRef = React.createRef();
        this.reset_usernameRef = React.createRef();
        this.pwdStrength = 0;
    }

    componentDidMount() {
        //add query check.
    }

    componentDidUpdate(prevProps) {
      this.reset_spinner();
    }

    handleCheckboxChange(evt) {
      this.setState({ checkboxChecked: evt.target.checked });
    }

    reset_spinner() {
      if (this.state.btn_disabled) {
        this.setState({btn_disabled: false, loading: false});
      }
    }

    request_reset_click(user) {
        this.setState({btn_disabled: true, loading: true});
        this.props.requestReset(user);
    }

    render() {
        const {resetNotice} = this.props;
        return (
            <div className="login-form">
                <h2 className="text-center">Reset Password</h2>

                <div className="form-group">
                    Step 1. Fill in your email to get a verification code.
                    <input type="text" className="form-control reset_form_val" placeholder="Email" ref={this.reset_usernameRef} required="required"/>
                </div>
                <div className="form-group">
                    <button disabled={this.state.btn_disabled}
                            className="btn btn-primary btn-block"
                            onClick={() => this.request_reset_click(this.reset_usernameRef.current.value)}>Get Verification Code</button>
                </div>
                {resetNotice &&
                <div>
                    {resetNotice.notice}
                </div>
                }
            </div>
        );
    }
};

export default connect(
    state => state.auth,
    dispatch => bindActionCreators(actionCreators, dispatch)
)(withRouter(ResetPasswordVerifyPage));
