
const dev = {
    webapiV1: {
        REGION: 'us-east-1',
    },
    webapiV2: {
        URL: "https://webapi-dev.flexcompute.com",
    },
    cognito: {
        REGION: "us-east-1",
        USER_POOL_ID: "us-east-1_kjeChiCm8",
        APP_CLIENT_ID: "1imult4e80s1lfocqjpfv69rm2",
        IDENTITY_POOL_ID: "us-east-1:6cdace1e-db96-4a52-b513-ceb212913de9"
    },
    tidy3d: {
        webclient:"https://client-dev.simulation.com"
    }
};

const prod = {

    webapiV1: {
        REGION: 'us-gov-west-1',
    },
    webapiV2: {
        URL: "https://webapi.flexcompute.com",
    },
    cognito: {
        REGION: "us-east-1",
        USER_POOL_ID: "us-east-1_kfHmLjlPl",
        APP_CLIENT_ID: "45krrjc3bm1snlddbo79jfpk1d",
        IDENTITY_POOL_ID: "us-east-1:dc691611-c253-4448-9b96-2bc507940155"
    },
    tidy3d: {
        webclient:"https://client.simulation.cloud"
    }
};

export const config = process.env.REACT_APP_RUNTIME_ENV === 'PROD'
    ? prod
    : dev;
