import React from "react";
import {connect} from "react-redux"
import {bindActionCreators} from "redux";
import {actionCreators} from "../reducer/AuthReducer";
import "./LoginPage.css"
import { withRouter } from "react-router";
import {Alert, OverlayTrigger, Popover, ProgressBar} from "react-bootstrap";
import {BarLoader} from "react-spinners";
import {test_password_strength} from "../reducer/utils";
import {openInNewTab} from "../util/WebUtils";
import {config} from "../util/EnvConfig";

class ResetPasswordVerifyPage extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
          loading: false,
          btn_disabled: false,
          checkboxChecked: false,
        };
        this.usernameRef = React.createRef();
        this.inviteCodeRef = React.createRef();
        this.companyRef = React.createRef();
        this.personNameRef = React.createRef();
        this.passwordRef = React.createRef();
        this.passwordRefSecond = React.createRef();
        this.verify_usernameRef = React.createRef();
        this.verify_codeRef = React.createRef();
        this.reset_usernameRef = React.createRef();
        this.pwdStrength = 0;
    }

    componentDidMount() {
        let query =  new URLSearchParams(this.props.location.search);
        //add query check.
        let userEmail = query.get("userEmail");
        let verifyCode = query.get("verifyCode");
        this.setState({userEmail:userEmail, verifyCode:verifyCode})
    }

    componentDidUpdate(prevProps) {
      this.reset_spinner();
    }

    handleCheckboxChange(evt) {
      this.setState({ checkboxChecked: evt.target.checked });
    }

    handlePwdChange(event) {
        let res = test_password_strength(event.target.value);
        let new_strength = res / 4 * 100;
        if (new_strength != this.state.pwdStrength) {
            this.setState({pwdStrength: new_strength});
        }
    }
    reset_spinner() {
      if (this.state.btn_disabled) {
        this.setState({btn_disabled: false, loading: false});
      }
    }

    openTidy3DClient() {
        return openInNewTab(config.tidy3d.webclient,"Tidy3dClient");
    }
    reset_click(user, verify_code, pwdOne, pwdTwo) {
        this.setState({btn_disabled: true, loading: true});
        this.props.resetPwd(user, verify_code, pwdOne, pwdTwo);
    }

    render() {
        const {resetNotice} = this.props;
        const {userEmail, verifyCode} = this.state;
        const popover = (
            <Popover id="popover-basic" title="Choose strong password">
                Password must have at least 8 characters, with at least 1 special character (!@#$%^&), 1 numeric, 1 lower and 1 upper case letter.
            </Popover>
        );

        if(resetNotice && resetNotice.isSuccess) {
            return (
                <div className="login-form">
                    <div className="form-group">
                        {resetNotice.notice}
                    </div>
                    <button
                        className="btn btn-primary btn-block"
                        onClick={() => this.openTidy3DClient()}>Go Tidy3D Client
                    </button>
                </div>
            );
        }
        else {
            return (
                <div className="login-form">
                    <h2 className="text-center">Reset Password</h2>
                    Step 2. Input your new password
                    <div className="form-group">
                        <OverlayTrigger trigger="focus" placement="right" overlay={popover}>
                            <input type="password" onChange={(evt) => this.handlePwdChange(evt)}
                                   className="form-control reset_form_val" placeholder="Password" ref={this.passwordRef}
                                   required="required"/>
                        </OverlayTrigger>
                    </div>
                    <ProgressBar now={this.state.pwdStrength} label="strength of password" className="prog_bar_green"/>
                    <div className="form-group">
                        <input type="password" className="form-control reset_form_val" placeholder="Confirm Password"
                               ref={this.passwordRefSecond} required="required"/>
                    </div>

                    <div className="form-group">
                        <button disabled={this.state.btn_disabled}
                                className="btn btn-primary btn-block"
                                onClick={() => this.reset_click(userEmail,
                                    verifyCode, this.passwordRef.current.value, this.passwordRefSecond.current.value)}>Reset
                            Password
                        </button>
                    </div>

                    <div className="form-group">
                        {resetNotice &&
                        <Alert variant={"warning"}>
                            {resetNotice.notice}
                        </Alert>}
                    </div>
                    <div className='sweet-loading'>
                        <BarLoader
                            width={340}
                            height={4}
                            color={'#337ab7'}
                            loading={this.state.loading}
                        />
                    </div>

                </div>
            );
        }
    }


}

export default connect(
    state => state.auth,
    dispatch => bindActionCreators(actionCreators, dispatch)
)(withRouter(ResetPasswordVerifyPage));
