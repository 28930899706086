import {createStore, applyMiddleware, combineReducers} from "redux";
import thunk from "redux-thunk";
import {createLogger} from 'redux-logger';

import {reducer as authReducer} from "./reducer/AuthReducer";

export default createStore(
    combineReducers({auth: authReducer}),
    applyMiddleware(thunk, createLogger())
);
